import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery, Link } from 'gatsby'
import Layout from '../../../layouts'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { CartItemsContext } from '../../../context/CartItemsContext'
import cartIcon from '../../../img/cart-white.svg'
import AddToCartBtn from '../../../components/common/AddToCartBtn'

const Category07Roll = (props) => {
  // const [cart, setCart] = useState([])
  const [cart, setCart] = useContext(CartItemsContext)
  const [activeFirst, setActiveFirst] = useState(false)
  const [activeSecond, setActiveSecond] = useState(false)
  const [activeThird, setActiveThird] = useState(false)
  const [activeColor, setActiveColor] = useState('#DF8136')

  // console.log(cart)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // setCart([JSON.parse(localStorage.getItem("click&collect-cart"))])
      if(localStorage.getItem("click&collect-cart") !== null) {
        let localCart = localStorage.getItem("click&collect-cart")
        setCart(JSON.parse(localCart))
      }else{
        localStorage.setItem("click&collect-cart", JSON.stringify(cart))
      }
    }
  }, [props])

  // useEffect(() => {
  //   if (cart == null) {
  //     setCart([{name: 'abc', price: 'dfd'}])
  //   }
  // }, [props]);


    // const addToCart = (e, name) => {
    //   alert(name)
    //   localStorage.setItem("click&collect-cart", name)
    // }


  const addToCart = (e, name, price) => {
    if (localStorage.getItem("click&collect-cart") !== null) {
      let cartCopy = [...cart]
      // create a copy of our cart state, avoid overwritting existing state

      // assuming we have an ID field in our item
      // name = item;
      const item = {
        name: name,
        price: price
      }

      // look for item in cart array
      // let existingItem = cartCopy.find(cartItem => cartItem.name == name);

      // if item already exists
      // if (existingItem) {
      //     existingItem.quantity += item.quantity //update item
      // } else { //if item doesn't exist, simply add it
      //   cartCopy.push(item)
      // }

      cartCopy.push(item)
      // cartCopy = [...cart, item]

      //update app state
      setCart(cartCopy)

      // make cart a string and store in local space
      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem("click&collect-cart", stringCart)
    } else {

      //assuming we have an ID field in our item
      // name = item;
      const item = {
        name: name,
        price: price
      }

      // localStorage.setItem("click&collect-cart", '')

      // look for item in cart array
      // let existingItem = cartCopy.find(cartItem => cartItem.name == name);

      // if item already exists
      // if (existingItem) {
      //     existingItem.quantity += item.quantity //update item
      // } else { //if item doesn't exist, simply add it
      //   cartCopy.push(item)
      // }

      // cartCopy = [...cart, item]

      // update app state
      setCart([item])

      // setCart(prevCart => ([...prevCart, ...item])

      // make cart a string and store in local space
      let stringCart = JSON.stringify(cart);
      localStorage.setItem("click&collect-cart", stringCart)
    }
  }

  const handleFirstActiveTab = () => {
    setActiveFirst(true)
    setActiveSecond(false)
    setActiveThird(false)
    sessionStorage.setItem("ActiveCat07Tab", 1)
  }

  const handleSecondActiveTab = () => {
    setActiveSecond(true)
    setActiveFirst(false)
    setActiveThird(false)
    sessionStorage.setItem("ActiveCat07Tab", 2)
  }

  const handleThirdActiveTab = () => {
    setActiveThird(true)
    setActiveFirst(false)
    setActiveSecond(false)
    sessionStorage.setItem("ActiveCat07Tab", 3)
  }

  useEffect(() => {
    // CHECK ACTIVE TAB
    if (typeof window !== 'undefined') {
      if (sessionStorage.getItem("ActiveCat07Tab") === '1') {
        setActiveFirst(true)
      }
      if (sessionStorage.getItem("ActiveCat07Tab") === '2') {
        setActiveSecond(true)
      }
      if (sessionStorage.getItem("ActiveCat07Tab") === '3') {
        setActiveThird(true)
      }
      if (sessionStorage.getItem("ActiveCat07Tab") === null) {
        setActiveFirst(true)
      }
    }
  }, [])

  const { data } = props
  const { edges: posts } = data.allMarkdownRemark
  const { markdownRemark: post, footerData, navbarData, bannerData } = data

  const category = props.data.category.edges[0].node

  return (
    <Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData}>
      <div className='imageContainer' style={{ position: 'relative' }}>
        <Img
          // fluid={data.image.childImageSharp.fluid}
          fluid={category.frontmatter.categories.category07.image.childImageSharp.fluid}
          style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(40%)'
          }}
        />
      </div>
      <section id='Category07' className='section' style={{ position: 'relative' }}>

        <div className='container'>
          <div className='columns is-multiline' style={{ marginTop: '2rem' }}>
            <div className='column is-12 has-text-centered'>
              <div style={{ position: 'relative' }}>
                <h1 className='title is-2 has-text-white-ter' style={{ marginBottom: '3rem' }}>
                  {category.frontmatter.categories.category07.title}
                </h1>
                <p className='subtitle is-5 has-text-centered has-text-white-ter' style={{ hyphens: 'auto', textAlign: 'justify' }}>
                  {category.frontmatter.categories.category07.description}
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className='column is-12'>
              <div className="buttons has-addons is-centered">
                <ToggleBtn
                  // to='/karte/heiss/#kaffee'
                  className="button"
                  onClick={handleFirstActiveTab}
                  style={{ backgroundColor: activeFirst ? activeColor : '#fff', color: activeFirst ? '#fff' : '#000' }}
                >
                  Herren
                </ToggleBtn>
                <ToggleBtn
                  // to='/karte/heiss/#tee'
                  className="button"
                  onClick={handleSecondActiveTab}
                  style={{ backgroundColor: activeSecond ? activeColor : '#fff', color: activeSecond ? '#fff' : '#000' }}
                >
                  Damen
                </ToggleBtn>
                <ToggleBtn
                  className="button"
                  onClick={handleThirdActiveTab}
                  style={{ backgroundColor: activeThird ? activeColor : '#fff', color: activeThird ? '#fff' : '#000' }}
                >
                  Kinder
                </ToggleBtn>
              </div>
            </div>
          </div>

          <Bg>
            {activeFirst ?
              <>
                <div className='columns is-multiline is-centered' style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                  <div className='column is-12'>
                    <CategoryBg className='title is-5 has-text-primary'>
                      Herren
                      <div className='triangle-right' />
                    </CategoryBg>
                  </div>
                  {posts && (posts
                    .filter(({ node: post }) => (post.frontmatter.category === 'Men' && post.frontmatter.active === 'ja')).map(({ node: post }) => (
                      <React.Fragment key={post.id}>
                        <div
                          className='is-parent column is-3'
                          style={{ minWidth: '180px', margin: '0 auto', position: 'relative' }}
                        >
                          <Link to={post.fields.slug}>
                            <Product>
                              {post.frontmatter.image ?
                                <Zoom>
                                  <Image
                                    fluid={post.frontmatter.image.childImageSharp.fluid}
                                    alt='Product Image'
                                  />
                                </Zoom>
                                : <ImageLess />}
                              <ProductInfo>
                                <Title>{post.frontmatter.title}</Title>
                                <Size>Größe: <b>{post.frontmatter.size}</b></Size>
                                <Description>{post.frontmatter.description}</Description>
                                <PriceTag>€{' '}{post.frontmatter.price.replace('.', ',')}</PriceTag>

                              </ProductInfo>

                            </Product>
                          </Link>
                          <AddToCartBtn onClick={(e) => addToCart(e, `${post.frontmatter.title}`, `${post.frontmatter.price.replace('.', ',')}`)}>
                            <img src={cartIcon} alt='Warenkorb' style={{ width: '25px' }} />
                          </AddToCartBtn>
                        </div>
                      </React.Fragment>
                    )))}
                </div>
              </>
              : null}

            {activeSecond ?
              <div className='columns is-multiline is-centered' style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                <div className='column is-12'>
                  <CategoryBg className='title is-5 has-text-primary'>
                    Damen
                    <div className='triangle-right' />
                  </CategoryBg>
                </div>
                {posts && (posts
                  .filter(({ node: post }) => (post.frontmatter.category === 'Women' && post.frontmatter.active === 'ja')).map(({ node: post }) => (
                    <React.Fragment key={post.id}>
                      <div
                        className='is-parent column is-3'
                        style={{ minWidth: '180px', margin: '0 auto', position: 'relative' }}
                      >
                        <Link to={post.fields.slug}>
                          <Product>
                            {post.frontmatter.image ?
                              <Zoom>
                                <Image
                                  fluid={post.frontmatter.image.childImageSharp.fluid}
                                  alt='Product Image'
                                />
                              </Zoom>
                              : <ImageLess />}
                            <ProductInfo>
                              <Title>{post.frontmatter.title}</Title>
                              <Size>Größe: <b>{post.frontmatter.size}</b></Size>
                              <Description>{post.frontmatter.description}</Description>
                              <PriceTag>€{' '}{post.frontmatter.price.replace('.', ',')}</PriceTag>

                            </ProductInfo>

                          </Product>
                        </Link>
                        <AddToCartBtn onClick={(e) => addToCart(e, `${post.frontmatter.title}`, `${post.frontmatter.price.replace('.', ',')}`)}>
                          <img src={cartIcon} alt='Warenkorb' style={{ width: '25px' }} />
                        </AddToCartBtn>
                      </div>
                    </React.Fragment>
                  )))}
              </div>
              : null}


            {activeThird ?
              <div className='columns is-multiline is-centered' style={{ marginTop: '2rem', marginBottom: '3rem' }}>
                <div className='column is-12'>
                  <CategoryBg className='title is-5 has-text-primary'>
                    Kinder
                    <div className='triangle-right' />
                  </CategoryBg>
                </div>
                {posts && (posts
                  .filter(({ node: post }) => (post.frontmatter.category === 'Kids' && post.frontmatter.active === 'ja')).map(({ node: post }) => (
                    <React.Fragment key={post.id}>
                      <div
                        className='is-parent column is-3'
                        style={{ minWidth: '180px', margin: '0 auto', position: 'relative' }}
                      >
                        <Link to={post.fields.slug}>
                          <Product>
                            {post.frontmatter.image ?
                              <Zoom>
                                <Image
                                  fluid={post.frontmatter.image.childImageSharp.fluid}
                                  alt='Product Image'
                                />
                              </Zoom>
                              : <ImageLess />}
                            <ProductInfo>
                              <Title>{post.frontmatter.title}</Title>
                              <Size>Größe: <b>{post.frontmatter.size}</b></Size>
                              <Description>{post.frontmatter.description}</Description>
                              <PriceTag>€{' '}{post.frontmatter.price.replace('.', ',')}</PriceTag>

                            </ProductInfo>

                          </Product>
                        </Link>
                        <AddToCartBtn onClick={(e) => addToCart(e, `${post.frontmatter.title}`, `${post.frontmatter.price.replace('.', ',')}`)}>
                          <img src={cartIcon} alt='Warenkorb' style={{ width: '25px' }} />
                        </AddToCartBtn>
                      </div>
                    </React.Fragment>
                  )))}
              </div>
              : null}
            <div className='column is-12 has-text-centered' style={{ marginTop: '2rem' }}>
              <Link className='button is-primary' to='/products'>zurück zur Auswahl
              </Link>
            </div>
          </Bg>
        </div>
      </section>
    </Layout>
  )
}


Category07Roll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
    query Category07RollQueryFull {
      allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___title] },
        filter: { frontmatter: { templateKey: { eq: "category07-post" } }}
      ) {
        edges {
          node {
            excerpt(pruneLength: 100)
            id
            fields {
              slug
            }
            frontmatter {
              image {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                  }
                }
              }
              title
              price
              amount
              size
              active
              category
              description
              templateKey
              date(formatString: "DD.MM.YYYY")
            }
          }
        }
      }
      ...LayoutFragment
      category: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "cart-categories" } }}
      ) {
        edges {
          node {
            frontmatter {
              categories{
                category07{
                  title
                  description
                  image {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(data, count) => (
      <Category07Roll data={data} count={count} />
    )}
  />
)

const Bg = styled.div`
  border-radius: 3px;
  // background-color: rgba(255,255,255,.7);
  @media(min-width: 767px){
    box-shadow: 0 0 15px rgba(0,0,0,.6);
    padding: 10px;
  }
  // display: block;
  margin: 0 auto;
  position: relative;

`

const CartItem = styled.div`
  max-width: 700px;
  margin: 0 auto;
  &:hover{
    transform: scale(1.02);
    transition: all 0.3s ease;
  }
`

const CategoryBg = styled.h1`
  background: #fff;
  display: block;
  width: 50%;
  padding: 10px;
  white-space: nowrap;
  position: relative;
  div.triangle-right {
    display: block;
    position: absolute;
    right: -25px;
    top: 0;
    width: 25px;
  	// height: auto;
  	border-top: 25px solid transparent;
  	border-left: 50px solid #fff;
  	border-bottom: 25px solid transparent;
    transform: rotate(90deg);
  }
`

const ToggleBtn = styled.button`
  &:active{
    border: 1px solid #fff;
    outline: none !important;
    background: #DF8136 !important;
    color: #fff !important;
  }
  &:focus{
    border: 1px solid #fff;
    outline: none !important;
    background: #DF8136 !important;
    color: #fff !important;
  }
`

const Product = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  position: relative;
  height: 350px;
`

const ProductInfo = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  // padding-bottom: 40px;
  // position: relative;
`

const Title = styled.h1`
  font-family: 'Montserrat';
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  color: #333333;
  font-size: 1rem,
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
`

const Description = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: auto;
  height: 30px;
  overflow: hidden;
  color: #333333;
`

const Size = styled.p`
  font-weight: 300;
  font-size: 1rem;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: auto;
  // height: 50px;
  color: #333333;
`

const PriceTag = styled.span`
  font-weight: 900;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 15px;
  color: #333333;
  position: absolute;
  left: 10px;
  bottom: 10px;
`

const Zoom = styled.div`
  overflow: hidden;
  height: 200px;
`

const ImageLess = styled.div`
  max-width: 100%;
  margin: 0px;
  background: #000;
  height: 200px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  transition: transform .5s ease;
  /* &:hover{
    transform: scale(1.1);
  } */
`

const Image = styled(Img)`
  max-width: 100%;
  height: 200px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  transition: transform .5s ease;
  &:hover{
    transform: scale(1.1);
  }
`
